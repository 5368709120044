.header {
  text-align: center;
  padding: 20px;
  background-image: url(../../assets//shapka_1280x713.jpg);
  font-weight: bold;
  font-size: 14px;

  span {
    background-color: #fff;
  }
}

.logo {
  width: 200px;
  margin-bottom: 20px;
}

.textWrapper {
  margin: 0 auto;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}
