@import "../../../colors.scss";

:global(.my_lastsongs) {
  & > :first-of-type,
  & > :last-of-type {
    display: none;
  }
}

.pleer {
  border-radius: 5px;
  margin-bottom: 15px;
  outline: none;
  background-color: $pink;
  svg {
    color: #000;
  }
}

.pleerWrapper {
  display: flex;
}

.currentSong {
  padding-left: 20px;
  flex: 5;
  height: 100%;
  display: flex;
  align-items: center;
}

.lastSongItem {
  display: flex;
  color: #fff;
  padding-bottom: 5px;
  &:only-child {
    //color: red;
  }
}

.lastSongItemSong {
  padding-left: 20px;
}

.box {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  padding: 15px;

  border-radius: 5px;
  overflow: hidden;
  background-color: #434343;
}

.nowPlaying {
  display: flex;
  align-items: flex-start;
}

.nowPlayingTitle {
  padding-right: 20px;
  flex: 0 0 30%;
  color: $pink;
  font-weight: bold;
}

.lastPlayedTitle {
  display: block;
  color: $pink;
  font-weight: bold;
  padding-bottom: 10px;
}

.socialTitle {
  display: block;
  color: $pink;
  font-weight: bold;
  padding-bottom: 10px;
}

.socialLinks {
  display: flex;
  justify-content: center;
}

.icon {
  height: 40px;
  width: 40px;
  margin: 0 10px;
  &:hover {
    fill: $pink;
  }
}
