.container {
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @media only screen and (max-width: 1280px) {
    max-width: 100%;
  }
}
