
body {
    margin: 0;
    padding: 0;
    background: #000;
    background-image: url(./assets/666.jpg);
    background-position: bottom;
    background-size: auto;
    background-repeat: space no-repeat;
    font-family: "Tahoma", sans-serif;
}


#curentsong19408 {
    height: 50px;
}

#nowPlaying {
    height: 50px;
    width: 100%;
    /* display: none; */
}

#lastPlayed {
    width: 100%;
}

.rhap_volume-indicator {
    background-color: #000;
  }