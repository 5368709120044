@import "../../../colors.scss";

.footer {
  background-color: $pink;

  color: #000;
  font-weight: 500;
  text-align: center;
  padding: 15px;
}

.mail {
  padding-left: 10px;
  display: inline-block;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  &:hover {
    color: $pink;
    background-color: #000;
  }
}
