.content {
  display: flex;
  justify-content: space-between;
  // height: 100%;
  background-color: #000;

  @media only screen and (max-width: 1280px) {
    flex-wrap: wrap;
    max-width: 700px;
    margin: 0 auto;
  }
  @media only screen and (max-width: 768px) {
  }
}

.pleer {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: 1280px) {
    flex: 100%;
  }
}

.item {
  padding: 15px;
}

.youtubeIframes {
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1280px) {
    order: 2;
  }
  @media only screen and (max-width: 768px) {
    flex: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
}

.ybIframe {
  border-radius: 5px;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}
.vk {
  iframe {
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
}
